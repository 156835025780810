<template>
  <v-dialog
    v-model="dataTableUOMSettingsDialog.show"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="600px"
  >
    <v-card>
      <v-card-title class="card-title">
        <h3>
          {{ $t("dataTableSettings.title") }}
        </h3>
      </v-card-title>

      <v-card-text>
        <v-container
          class="pa-0"
          :class="{ 'pa-3': $vuetify.breakpoint.mdAndUp }"
        >
          <v-row>
            <v-col>
              <v-data-table
                id="content"
                :headers="headers"
                :items="filteredItems"
                :search="search"
                sort-by="text"
                :items-per-page="5"
                @click:row="select"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-btn-toggle
                      depressed
                      tile
                      dark
                      mandatory
                      :value="uom"
                      @change="uomChanged"
                    >
                      <v-btn
                        color="primary"
                        v-html="$t('sawCalc.inputUOM.imperial')"
                        :x-small="$vuetify.breakpoint.smAndDown"
                      ></v-btn>
                      <v-btn
                        color="primary"
                        v-html="$t('sawCalc.inputUOM.metric')"
                        :x-small="$vuetify.breakpoint.smAndDown"
                      ></v-btn>
                    </v-btn-toggle>

                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('admin.machines.search')"
                      single-line
                      hide-details
                      style="max-width: 300px;"
                    ></v-text-field>
                  </v-toolbar>
                </template>
                <template v-slot:item.selected="{ item }">
                  <v-icon v-show="item.selected">
                    mdi-check-bold
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions
        :class="{
          'padding-action-small': $vuetify.breakpoint.smAndDown,
          'padding-action': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-container class="px-1 py-0">
          <v-row>
            <v-col class="pt-0">
              <v-btn
                depressed
                tile
                v-html="$t('dataTableSettings.cancelButton')"
                class="primary white--text full-width"
                :disabled="dataTableUOMSettingsDialog.loading"
                :loading="dataTableUOMSettingsDialog.loading"
                @click="cancel"
              ></v-btn>
            </v-col>
            <v-col class="pt-0">
              <v-btn
                depressed
                tile
                v-html="$t('dataTableSettings.updateButton')"
                class="primary white--text full-width"
                :disabled="dataTableUOMSettingsDialog.loading"
                :loading="dataTableUOMSettingsDialog.loading"
                @click="save"
              ></v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DataTableOUMSettings",

  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    inputUOM: {
      type: Number,
      required: true,
      default: () => 0,
    },
  },

  data() {
    return {
      uom: 0,
      headers: [
        {
          text: this.$t("dataTableSettings.selectedLabel"),
          value: "selected",
        },
        {
          text: this.$t("dataTableSettings.columnLabel"),
          value: "text",
        },
      ],
      originalItems: [],
      filteredItems: [],
      search: "",
    };
  },

  computed: {
    ...mapGetters(["dataTableUOMSettingsDialog"]),
    metric() {
      return this.uom > 0;
    },
  },

  methods: {
    setDefaults() {
      this.uom = this.inputUOM;
    },
    copyItems() {
      this.originalItems = [];

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        const original = JSON.parse(JSON.stringify(item));
        this.originalItems.push(original);
      }
    },
    uomChanged(index) {
      this.uom = index;

      this.filteredItems = this.items.filter(
        (item) => item.uom == null || item.uom == index
      );

      this.$store.dispatch("changeInputUOM", this.metric);
    },
    select(col) {
      const item = this.items.find((itm) => itm.value == col.value);

      if (item) {
        item.selected = !item.selected;
      }
    },
    cancel() {
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];

        const original = this.originalItems.find(
          (itm) => itm.value == item.value
        );

        if (original) {
          item.selected = original.selected;
        }
      }

      this.$store.dispatch("showDataTableUOMSettingsDialog", false);
    },
    save() {
      this.$emit("updateSettings", { uom: this.uom, headers: this.items });
      this.$store.dispatch("showDataTableUOMSettingsDialog", false);
    },
  },

  mounted() {
    this.setDefaults();
    this.copyItems();
    this.uomChanged(this.uom);
  },
};
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__title {
  padding: 20px 20px 0px 20px;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px 20px 0px 10px;
}

.padding-input {
  padding: 0 10px;
}

.padding-action {
  padding: 0px 20px 20px 20px;
}

.padding-action-small {
  padding: 5px 20px 10px 20px;
}

.v-btn {
  max-height: 30px;
  min-height: 23px;
}
</style>

<template>
  <v-data-table
    id="content"
    :headers="headers"
    :items="machinesAdmin"
    :search="search"
    sort-by="manufacturerName"
    :loading="loading"
    :loading-text="$t('admin.machines.loadingWait')"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title
          ><h2>{{ $t("admin.machines.title") }}</h2></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('admin.machines.search')"
          single-line
          hide-details
          style="max-width: 300px;"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          text
          icon
          color="primary"
          :disabled="loading"
          @click="showSettings()"
        >
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
        <v-btn text icon color="primary" :disabled="loading" @click="addItem()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <DataTableUOMSettings
          :items="dataTableItems"
          :inputUOM="inputUOM"
          @updateSettings="updateSettings($event)"
          :key="dataTableUOMSettingsKey"
        />

        <v-dialog
          v-model="dialog"
          persistent
          :fullscreen="$vuetify.breakpoint.smAndDown"
          max-width="520px"
        >
          <Imperial
            v-if="!metric"
            :bladeWidthImperialList="bladeWidthImperialList"
            :bladeThicknessImperialList="bladeThicknessImperialList"
            :bladeLengthFtList="bladeLengthFtList"
            :bladeLengthInList="bladeLengthInList"
            :bladeLengthFrList="bladeLengthFrList"
            :item="editedItem"
            @close="close"
            @machinesUpdated="machinesUpdated"
            :key="unknownMachineImperialKey"
          />
          <Metric
            v-else
            :bladeWidthMetricList="bladeWidthMetricList"
            :bladeThicknessMetricList="bladeThicknessMetricList"
            :bladeLengthMetricList="bladeLengthMetricList"
            :item="editedItem"
            @close="close"
            @machinesUpdated="machinesUpdated"
            :key="unknownMachineMetricKey"
          />
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.bladeWidthFr="{ item }">
      <span v-if="item.bladeWidthFr > 0 && item.bladeWidthFr <= 0.125"
        >1/8</span
      >
      <span v-else-if="item.bladeWidthFr > 0.125 && item.bladeWidthFr <= 0.25"
        >1/4</span
      >
      <span v-else-if="item.bladeWidthFr > 0.25 && item.bladeWidthFr <= 0.375"
        >3/8</span
      >
      <span v-else-if="item.bladeWidthFr > 0.375 && item.bladeWidthFr <= 0.5"
        >1/2</span
      >
      <span v-else-if="item.bladeWidthFr > 0.5 && item.bladeWidthFr <= 0.625"
        >5/8</span
      >
      <span v-else-if="item.bladeWidthFr > 0.625 && item.bladeWidthFr <= 0.75"
        >3/4</span
      >
      <span v-else-if="item.bladeWidthFr > 0.75 && item.bladeWidthFr <= 0.875"
        >7/8</span
      >
      <span v-else></span>
    </template>
    <template v-slot:item.bladeLengthFr="{ item }">
      <span v-if="item.bladeLengthFr > 0 && item.bladeLengthFr <= 0.125"
        >1/8</span
      >
      <span v-else-if="item.bladeLengthFr > 0.125 && item.bladeLengthFr <= 0.25"
        >1/4</span
      >
      <span v-else-if="item.bladeLengthFr > 0.25 && item.bladeLengthFr <= 0.375"
        >3/8</span
      >
      <span v-else-if="item.bladeLengthFr > 0.375 && item.bladeLengthFr <= 0.5"
        >1/2</span
      >
      <span v-else-if="item.bladeLengthFr > 0.5 && item.bladeLengthFr <= 0.625"
        >5/8</span
      >
      <span v-else-if="item.bladeLengthFr > 0.625 && item.bladeLengthFr <= 0.75"
        >3/4</span
      >
      <span v-else-if="item.bladeLengthFr > 0.75 && item.bladeLengthFr <= 0.875"
        >7/8</span
      >
      <span v-else></span>
    </template>
    <template v-slot:item.hortizontal="{ item }">
      <span v-if="item.hortizontal">{{
        $t("admin.machines.hortizontal")
      }}</span>
      <span v-else>{{ $t("admin.machines.vertical") }}</span>
    </template>
    <template v-slot:item.scissorPivot="{ item }">
      <v-icon v-show="item.scissorPivot">
        mdi-check
      </v-icon>
    </template>
    <template v-slot:item.cantedHead="{ item }">
      <v-icon v-show="item.cantedHead">
        mdi-check
      </v-icon>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon class="mr-2" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <span>{{ $t("admin.machines.noMachinesFound") }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import DataTableUOMSettings from "@/components/common/DataTableUOMSettings";
import Imperial from "@/components/admin/Machine/Imperial";
import Metric from "@/components/admin/Machine/Metric";

import { config } from "@/config/ConfigSettings";
import { api } from "@/helpers/ApiCalls";

export default {
  name: "MachinesAdmin",

  components: {
    DataTableUOMSettings,
    Imperial,
    Metric,
  },

  data() {
    return {
      uom: 0,
      headersList: [
        {
          text: this.$t("admin.machines.manufacturerNameLabel"),
          value: "manufacturerName",
          uom: null,
        },
        {
          text: this.$t("admin.machines.modelLabel"),
          value: "model",
          uom: null,
        },
        {
          text: this.$t("admin.machines.bladeWidthInLabel"),
          value: "bladeWidthIn",
          uom: 0,
        },
        {
          text: this.$t("admin.machines.bladeWidthFrLabel"),
          value: "bladeWidthFr",
          uom: 0,
        },
        {
          text: this.$t("admin.machines.bladeWidthMetricLabel"),
          value: "bladeWidthMetric",
          uom: 1,
        },
        {
          text: this.$t("admin.machines.bladeThicknessImperialLabel"),
          value: "bladeThicknessImperial",
          uom: 0,
        },
        {
          text: this.$t("admin.machines.bladeThicknessMetricLabel"),
          value: "bladeThicknessMetric",
          uom: 1,
        },
        {
          text: this.$t("admin.machines.bladeLengthFtLabel"),
          value: "bladeLengthFt",
          uom: 0,
        },
        {
          text: this.$t("admin.machines.bladeLengthInLabel"),
          value: "bladeLengthIn",
          uom: 0,
        },
        {
          text: this.$t("admin.machines.bladeLengthFrLabel"),
          value: "bladeLengthFr",
          uom: 0,
        },
        {
          text: this.$t("admin.machines.bladeLengthImperialLabel"),
          value: "bladeLengthImperial",
          uom: 0,
        },
        {
          text: this.$t("admin.machines.bladeLengthMetricLabel"),
          value: "bladeLengthMetric",
          uom: 1,
        },
        {
          text: this.$t("admin.machines.hortizontalLabel"),
          value: "hortizontal",
          align: "center",
          uom: null,
        },
        {
          text: this.$t("admin.machines.scissorPivotLabel"),
          value: "scissorPivot",
          align: "center",
          uom: null,
        },
        {
          text: this.$t("admin.machines.cantedHeadLabel"),
          value: "cantedHead",
          align: "center",
          uom: null,
        },
        {
          text: this.$t("admin.machines.bandSpeedMinImperialLabel"),
          value: "bandSpeedMinImperial",
          uom: 0,
        },
        {
          text: this.$t("admin.machines.bandSpeedMaxImperialLabel"),
          value: "bandSpeedMaxImperial",
          uom: 0,
        },
        {
          text: this.$t("admin.machines.bandSpeedMinMetricLabel"),
          value: "bandSpeedMinMetric",
          uom: 1,
        },
        {
          text: this.$t("admin.machines.bandSpeedMaxMetricLabel"),
          value: "bandSpeedMaxMetric",
          uom: 1,
        },
        {
          text: this.$t("admin.machines.edit"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      headers: [],
      search: "",
      dialog: false,
      editedIndex: -1,
      editedItem: {
        machineID: 0,
        manufacturerID: 0,
        manufacturerName: "",
        model: "",
        bladeWidthIn: 0,
        bladeWidthFr: null,
        bladeWidthMetric: 0,
        bladeThicknessImperial: 0,
        bladeThicknessMetric: 0,
        bladeLengthFt: 0,
        bladeLengthIn: null,
        bladeLengthFr: null,
        bladeLengthImperial: 0,
        bladeLengthMetric: 0,
        hortizontal: false,
        scissorPivot: null,
        cantedHead: false,
        bandSpeedMinImperial: 0,
        bandSpeedMaxImperial: 0,
        bandSpeedMinMetric: 0,
        bandSpeedMaxMetric: 0,
      },
      defaultItem: {
        machineID: 0,
        manufacturerID: 0,
        manufacturerName: "",
        model: "",
        bladeWidthIn: 0,
        bladeWidthFr: null,
        bladeWidthMetric: 0,
        bladeThicknessImperial: 0,
        bladeThicknessMetric: 0,
        bladeLengthFt: 0,
        bladeLengthIn: null,
        bladeLengthFr: null,
        bladeLengthImperial: 0,
        bladeLengthMetric: 0,
        hortizontal: false,
        scissorPivot: null,
        cantedHead: false,
        bandSpeedMinImperial: 0,
        bandSpeedMaxImperial: 0,
        bandSpeedMinMetric: 0,
        bandSpeedMaxMetric: 0,
      },
      loading: false,
      dataTableItems: [],
    };
  },

  computed: {
    ...mapGetters([
      "machinesAdmin",
      "machinesAdminSettings",
      "bladeWidthImperialList",
      "bladeThicknessImperialList",
      "bladeLengthFtList",
      "bladeLengthInList",
      "bladeLengthFrList",
      "bladeWidthMetricList",
      "dataTableUOMSettingsKey",
      "bladeThicknessMetricList",
      "bladeLengthMetricList",
      "unknownMachineImperialKey",
      "unknownMachineMetricKey",
      "inputUOM",
    ]),
    metric() {
      return this.uom > 0;
    },
  },

  methods: {
    getMachines() {
      this.loading = true;

      this.$store.dispatch("getMachines").then(
        () => {
          this.getAdminMachines();
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function() {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    getAdminMachines() {
      this.loading = true;

      this.$store.dispatch("getAdminMachines").then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.updateHeaders(this.uom);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function() {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    showSettings() {
      this.updateDataTableItems();
      this.$store.dispatch("showDataTableUOMSettingsDialog", true);
    },
    updateSettings(params) {
      this.$store.dispatch("saveMachinesAdminSettings", params);

      this.uom = params.uom;
      this.updateHeadersFromSettings(this.uom, params.headers);
    },
    updateHeaders(uom) {
      if (this.settings()) {
        this.updateHeadersFromSettings(
          this.uom,
          this.machinesAdminSettings.headers
        );
      } else {
        this.headers = this.headersList.filter(
          (item) => item.uom == null || item.uom == uom
        );
      }
    },
    updateHeadersFromSettings(uom, headers) {
      var values = headers
        .filter(
          (item) => (item.uom == null || item.uom == uom) && item.selected
        )
        .map((item) => {
          return item.value;
        });

      this.headers = this.headersList.filter((item) =>
        values.includes(item.value)
      );
    },
    updateDataTableItems() {
      if (this.settings()) {
        this.uom = this.machinesAdminSettings.uom;
        this.dataTableItems = this.machinesAdminSettings.headers;
      } else {
        let items = this.headersList.map((o) => ({
          text: o.text + " (" + o.value + ")",
          value: o.value,
          uom: o.uom,
          selected: true,
        }));

        items.sort(function(a, b) {
          if (!a.text || !b.text) {
            return 0;
          }

          var x = a.text.toLowerCase();
          var y = b.text.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        this.dataTableItems = items;
      }

      this.$store.dispatch("reloadMachinesAdminSettingsComponent");
    },
    settings() {
      return (
        this.machinesAdminSettings &&
        this.machinesAdminSettings.uom != null &&
        this.machinesAdminSettings.headers != null
      );
    },
    addItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.machinesAdmin.indexOf(item);
      this.editedItem = Object.assign({}, item);

      const bladeWidthImperial = this.bladeWidthImperialList.find(
        ({ value }) =>
          value == this.editedItem.bladeWidthIn + this.editedItem.bladeWidthFr
      );

      this.editedItem.bladeWidthImperial = bladeWidthImperial;

      const bladeLengthFr = this.bladeLengthFrList.find(
        ({ value }) => value == this.editedItem.bladeLengthFr
      );

      this.editedItem.bladeLengthFr = bladeLengthFr;

      this.editedItem.machineOrientation = this.editedItem.hortizontal ? 0 : 1;
      this.editedItem.scissorPivot = this.editedItem.scissorPivot ? true : false;
      this.editedItem.cantedHead = this.editedItem.cantedHead ? true : false;

      this.dialog = true;
    },
    deleteItem(item) {
      const action = this.$t("admin.machines.deleteRecord");
      if (!confirm(action)) {
        return;
      }

      this.itemOverlay = true;

      this.$store.dispatch("deleteMachine", { machine: item }).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.machinesUpdated();
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    machinesUpdated() {
      this.dialog = false;
      this.getAdminMachines();
    },
    dbSync(dbSyncSeconds) {
      if (this.settingsLoaded) {
        this.$store.dispatch("syncRequired", dbSyncSeconds).then((response) => {
          if (response) {
            this.$store.dispatch("resetSawCalcReady");
            this.$store.dispatch("getSawCalcData").then((response) => {
              const error = api.responseError(response);

              if (error) {
                if (api.authenticationError(error.code)) {
                  setTimeout(function() {
                    router.push({ name: "Home" }).catch(() => {});
                  }, api.authErrorRedirectTimeout);
                }
              } else {
                this.$store.dispatch("checkInputs");
              }
            });
          } else {
            this.$store.dispatch("checkInputs");
          }
        });
      } else {
        setTimeout(() => this.dbSync(config.dbSyncSeconds()), 100);
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.dbSync(config.dbSyncSeconds());
    this.getMachines();
  },
};
</script>
